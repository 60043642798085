import { ElementRef, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DomService {
    protected scrollScreenTimer: any = null;

    constructor() { }

    closestTo(el: any, selector: string): any|null {
        do {
            if ((el.matches || el.matchesSelector).call(el, selector)) {
                break;
            }
        } while (el = el.parentElement);

        return el;
    }

    /**
     * Move page vertically by given element
     *
     * @param target Which element is attached to or at what position (px)
     * @param topOffset how many pixels should be subtracted from element (e.g. height of fixed headers or other top panels)
     * @param left Left position (if is different from 0)
     * @param behavior scroll Behavior
     */
    moveTo(target?: ElementRef|HTMLElement|string|number, topOffset: number = 120, left: number = 0, behavior: ScrollBehavior = 'smooth'): void {
        this.scrollScreenTimer && clearTimeout(this.scrollScreenTimer);
        this.scrollScreenTimer = setTimeout(() => {
            if (typeof window !== 'undefined') {

                const top = typeof target === 'number' ? target
                    // @ts-ignore
                    : ($(target?.nativeElement ?? target).offset()['top'] ?? 0);
                const position = (top - topOffset) < 0 ? 0 : (top - topOffset);

                window.scrollTo({left, top: position, behavior});
                this.scrollScreenTimer && clearTimeout(this.scrollScreenTimer);
            }
        }, 250);
    }

    moveToTop(): void {
        if (typeof document !== 'undefined' && 'documentElement' in document && 'scrollTop' in document.documentElement) {
            document.documentElement.scrollTop = 0;
        }

        // Safari (and older Chrome) fallback
        if (typeof document !== 'undefined' && 'body' in document && 'scrollTop' in document.body) {
            document.body.scrollTop = 0;
        }
    }

    moveToBottom(): void {
        setTimeout(() => {
            if (typeof document !== 'undefined' && 'documentElement' in document && 'scrollTop' in document.documentElement) {
                document.documentElement.scrollTop = document.documentElement.scrollHeight;
            }

            // Safari (and older Chrome) fallback
            if (typeof document !== 'undefined' && 'body' in document && 'scrollTop' in document.body) {
                document.body.scrollTop = document.body.scrollHeight;
            }
        });
    }
}
